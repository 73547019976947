<template>
	<div class="general-info">
		<form @submit.prevent="onSubmit" novalidate>
			<div class="row g-3">
				<div class="form-group mb-2">
					<label for="">Title</label>
					<input type="text" v-model="vv.title.$model" placeholder="Enter title" class="form-control">
					<span class="error">{{ vv?.title?.$errors[0]?.$message }}</span>
				</div>
				<div class="col-12">
					<label>Information Category</label>
					<select v-model="vv.news_type.$model" class="form-select">
						<option :value="initialValue">Select Information Category</option>
						<option value="1">General Information</option>
						<option value="2">Special Information</option>
					</select>
				</div>
				<div class="form-group">
					<label for="">Content</label>
					<ckeditor :editor="editor" v-model="vv.content.$model" :config="editorConfig"></ckeditor>
					<span class="error">{{ vv?.content?.$errors?.$message }}</span>
				</div>
				<div class="form-group mb-3">
					<img class="image" id="previewImage" :src="setImage(selectedImage)" alt="">
					<label class="custom-file-upload">
						<input type="file" @change="handleFileChange" id="profile-image-input" accept="image/*"/>
						Add Featured Image
					</label>
				</div>
				<div class="form-group" style="width: 100%;">
					<button class="btn btn-primary btn-block" :disabled="vv.$invalid" v-if="editMode && !isProcessing">Update</button>
					<button class="btn btn-primary btn-block" :disabled="vv.$invalid" v-if="!editMode && !isProcessing">Save</button>
					<button class="btn btn-primary btn-block" :disabled="isProcessing" v-if="isProcessing">
						<span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>Submitting Data</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Mathematics from 'ckeditor5-math/src/math';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import { newsService } from '@/services/news.service'
export default {
	name: 'GeneralInformationForm',
	props: {
		editMode: Boolean,
		postId: [Number, String]
	},
	setup () {
		const fform = reactive({
			title: '',
			content: '',
			news_type: null,
		})
		const rules = computed(() => (
		{
			title: { required },
			content: { required },
			news_type: { required }
		}
		))
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			initialValue: null,
			editor: ClassicEditor,
			editorConfig: {
				plugins: [
					EssentialsPlugin,
					BoldPlugin,
					ItalicPlugin,
					ParagraphPlugin,
					Mathematics,
					Alignment,
					Base64UploadAdapter,
					Image,
					ImageCaption,
					ImageStyle,
					ImageToolbar,
					ImageUpload,
					ImageResize
				],
				toolbar: {
					items: [
					'bold',
					'italic',
					'undo',
					'alignment',
					'redo',
					'uploadImage',
					'math'
					]
				},
				image: {
					toolbar: [
						'imageStyle:full',
						'imageStyle:side',
						'|',
						'imageTextAlternative'
					]
				},
				math: {
					engine: 'mathjax',
					lazyLoad: undefined,
					outputType: 'span',
					forceOutputType: false,
					enablePreview: true
				},
				height: 400,
				allowedContent: true
			},
			selectedImage: '',
			isImageChanged: false,
			isProcessing: false
		};
	},
	mounted() {
		if(this.editMode){
			this.loadContent()
		}
	},
	methods: {
		loadContent () {
			newsService.loadSingleNews(this.postId)
				.then((response) => {
					if(parseInt(response.data.message_code) === 200){
						const newsRow = response.data.newsRow
						this.fform.title = newsRow.title
						this.fform.news_type = newsRow.news_type
						this.fform.content = this.$generalHelper.decodeHtmlEntites(newsRow.content)
						this.selectedImage = newsRow.featured_image
						//console.log(response.data.newsRow)
					}else{
						this.$emit('errorClose', false)
					}
				})
				.catch((e) => {
					console.log(e)
					this.$emit('errorClose', false)
				})
		},
		setImage (image) {
			if(image.length === 0){
				return image
			}
			const index = image.indexOf('base64')
			if(index !== -1) {
				return image
			}else{
				return `${newsService.apiUrl}/news-image/${image}`
			}
		},
		async handleFileChange () {
			const elem = document.getElementById('profile-image-input')
			if(!elem.files[0]) return
			if (elem.files && elem.files[0]) {
				const image = await this.$imageHelper.compressServerImage(elem.files[0])
				this.selectedImage = image
				if(this.editMode){
					this.isImageChanged = true
				}
			}
		},
		onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;
			this.isProcessing = true
			if(!this.editMode){
				const postData = {
					title: this.fform.title,
					content: this.$generalHelper.encodeHtmlEntites(this.fform.content),
					news_type: this.fform.news_type,
					featured_image: this.selectedImage
				}
				newsService.submitNewsPost(postData)
					.then((response) => {
						this.isProcessing = false
						if(parseInt(response.data.message_code) === 200){
							this.$emit('closeForm', false)
						}else{
							this.$swal({
								icon: "error",
								text: "Error saving data"
							})
						}
					})
					.catch((e) => {
						this.isProcessing = false
						console.log(e)
						this.$swal({
							icon: "error",
							text: "Error saving data"
						})
					})
			}else{
				const postData = {
					title: this.fform.title,
					content: this.$generalHelper.encodeHtmlEntites(this.fform.content),
					news_type: this.fform.news_type,
					featured_image: this.selectedImage
				}
				newsService.updateNewsPost(postData, this.postId)
					.then((response) => {
						this.isProcessing = false
						if(parseInt(response.data.message_code) === 200){
							this.$emit('closeForm', true)
						}else{
							this.$swal({
								icon: "error",
								text: "Error saving data"
							})
						}
					})
					.catch((e) => {
						this.isProcessing = false
						console.log(e)
						this.$swal({
							icon: "error",
							text: "Error saving data"
						})
					})
			}
		}
	}
};
</script>

<style scoped>
label{
	font-weight: 550;
	font-size: 14px;
	margin-bottom: 5px;
}
input[type="file"] {
	display: none;
}
.custom-file-upload{
	margin-top: 0px;
	display: inline-block;
	padding: 5px 10px;
	cursor: pointer;
	background-color: var(--paul-color-lfx-new);
	height: auto;
	color: #ffffff;
	border-radius: 5px;
	font-weight: 400 !important;
}
.image{
	width: 100px;
	height: 100px;
	border-radius: 5px;
	position: relative;
	border: 4px solid var(--paul-color-light);
	background-color: var(--paul-color-light);
	margin-right: 20px;
}
</style>
<style type="text/css">
.general-info .ck.ck-editor__main>.ck-editor__editable{
	height: 200px !important;
}
.general-info .ck.ck-editor__main>.ck-editor__editable, .general-info .options .ck.ck-editor__main>.ck-editor__editable{
	min-height: 200px !important;
	height: 200px !important;
}
.general-info .ck.ck-editor__main>.ck-editor__editable p{
	margin-bottom: 2px;
	margin-top: 2px;
}
.general-info .ck.ck-button, a.ck.ck-button, .ck.ck-icon{
	font-size: 10px !important;
}
</style>
