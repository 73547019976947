<template>
	<div class="container mt-4">
		<h2 class="label-header">General / Special Information</h2>
		<button class="btn btn-primary btn-sm mb-3 mt-3" v-if="!openForm" @click.stop="toggleForm">Add New Information</button>
		<button class="btn btn-danger btn-sm mb-3 mt-3" v-if="openForm" @click.stop="toggleForm">Cancel</button>
		<div class="card mb-4" v-if="!openForm">
			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-hover table-striped table-bordered">
						<thead>
							<tr>
								<th>Title</th>
								<th>Category</th>
								<th width="100px">Action</th>
							</tr>
						</thead>
						<tbody v-if="isLoading">
							<tr>
								<td colspan="3">
									<div class="d-flex justify-content-center flex-column align-items-center">
										<span style="width: 30px; height: 30px;" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>Loading Data
									</div>
								</td>
							</tr>
						</tbody>
						<tbody v-if="!isLoading && newsList.length > 0">
							<tr v-for="(item, i) in newsList" :key="i">
								<td>
									{{ item.title }}
								</td>
								<td>
									<span v-if="parseInt(item.news_type) === 1" class="font-weight-bold text-info">General Information</span>
									<span v-if="parseInt(item.news_type) === 2" class="font-weight-bold text-danger">Special Information</span>
								</td>
								<td>
									<div class="btn-group">
										<button class="btn btn-sm btn-primary" style="margin-right: 10px;" @click="editInfo(item)">Edit</button>
										<button class="btn btn-sm btn-danger" style="margin-right: 10px;" @click="deleteInfo(item)">Delete</button>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody v-if="!isLoading && newsList.length === 0">
							<tr>
								<td colspan="3">
									<div class="d-flex justify-content-center flex-column align-items-center">
										No Video have been uploaded yet
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="d-flex justify-content-center flex-column align-items-center" v-if="lastIndex !== 0">
					<button class="btn btn-sm btn-secondary" @click="loadPage()">Load More</button>
				</div>
			</div>
		</div>
		<div class="card" v-if="openForm">
			<div class="card-body">
				<general-information-form :editMode="editMode" @closeForm="closeInfoForm" @errorClose="closeFormOnError" :postId="postId" />
			</div>
		</div>
	</div>
</template>

<script>
import GeneralInformationForm from '@/components/GeneralInformationForm.vue'
import { newsService } from '@/services/news.service'
export default {
	name: 'GeneralInformation',
	components: {
		GeneralInformationForm
	},
	data() {
		return {
			isLoading: true,
			lastIndex: 0,
			openForm: false,
			editMode: false,
			errorLoad: false,
			postId: null,
			newsList: []
		};
	},
	mounted() {
		this.loadPage()
	},
	methods: {
		loadPage () {
			newsService.loadGeneralInformation(this.lastIndex)
			.then((response) => {
				this.isLoading = false
				if(parseInt(response.data.message_code) === 200){
					for(const item of response.data.newsList){
						this.newsList.push(item)
					}
					this.lastIndex = response.data.lastIndex
					this.errorLoad = false
				}else{
					this.errorLoad = true
				}
			})
			.catch((e) => {
				this.isLoading = false
				this.errorLoad = true
				console.log(e)
			})
		},
		closeInfoForm (isEdit){
			this.openForm = false
			if(isEdit){
				this.editMode = false
				this.$swal("Data Saved Successful")
			}else{
				this.$swal("Data Saved Successful")
			}
			this.newsList = []
			this.lastIndex = 0
			this.loadPage()
		},
		closeFormOnError () {
			this.openForm = false
			this.editMode = false
			this.$swal({
				icon: "error",
				text: "Error occured try again later"
			})
		},
		toggleForm () {
			this.openForm = !this.openForm
			if(!this.openForm){
				this.editMode = false
			}
		},
		editInfo (item) {
			this.postId = item.news_id
			this.editMode = true
			this.openForm = true
		},
		deleteInfo (item) {
			newsService.deleteNews(item.news_id)
			.then((response) => {
				if(parseInt(response.data.message_code) === 200){
					this.isLoading = true
					this.newsList = []
					this.lastIndex = 0
					this.loadPage()
				}else{
					this.$swal({
						icon: "error",
						text: "Error occured try again later"
					})
				}
			})
			.catch((e) => {
				console.log(e)
				this.$swal({
					icon: "error",
					text: "Error occured try again later"
				})
			})
		}
	}
};
</script>

<style scoped>
.container{
	background: var(--paul-color-light);
}
</style>
