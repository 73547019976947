import axios from 'axios'
import { storageService } from './storage.service'

const baseUrl = storageService.setApiUrl()

class NewsService{
	apiUrl = baseUrl

	async loadGeneralInformation (lastIndex) {
		const apiToken = storageService.getItem().token
		return await axios.get(`${baseUrl}/load-news/${lastIndex}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async submitNewsPost (postData) {
		const apiToken = storageService.getItem().token
		return await axios.post(`${baseUrl}/submit-news`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async loadSingleNews (postId) {
		const apiToken = storageService.getItem().token
		return await axios.get(`${baseUrl}/load-news-single/${postId}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updateNewsPost (postData, postId) {
		const apiToken = storageService.getItem().token
		return await axios.put(`${baseUrl}/submit-news/${postId}`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteNews (postId) {
		const apiToken = storageService.getItem().token
		return await axios.delete(`${baseUrl}/delete-news/${postId}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

}
const newsService = new NewsService()
export { newsService }